<template>
  <div>
    <v-card-text class="modal-cadastro-form">
      <v-skeleton-loader
      :loading="loadingTable"
      :transition="'fade-transition'"
      height="350px"
      type="table">
        <v-data-table
        :headers="headers"
        :items="listExtratoCredito"
        flat
        dense
        class="table-extrato-creditos"
      >
          <template v-slot:item.value="{ item }">
            <span :style="`color: ${getColor(item.entryType ? item.entryType.type : null)};`">
              {{ item.value | currency }}
            </span>
          </template>

          <template v-slot:item.type="{ item }">
            <!-- {{ item.credits.length > 0 ? item.credits[0].extraInfo.type : '-' }} -->
            {{ getExtraInfo(item.credits) }}
          </template>

          <template v-slot:item.cod="{ item }">
            <!-- {{ exibirCod(item) }} -->

            <v-menu
              bottom
              offset-y
              max-height="300"
              v-if="isCreditsOrCupons(item) === 'CUPOM'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  tile
                  :color="variables.colorPrimary"
                  dark
                >
                  Ver cupons
                  <v-icon right>
                    mdi-arrow-down-drop-circle-outline
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, i) in exibirCod(item)"
                  :key="i"
                >
                  <v-list-item-title>{{ item.cod }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <span v-else>{{ exibirCod(item)  }}</span>


          </template>
          <template v-slot:item.purchase="{ item }">
            {{ exibirDateCompra(item) }}
          </template>
          <template v-slot:item.activate="{ item }">
            {{ exibirDateAtivacao(item) }}
          </template>

           <template slot="body.append">
            <tr>
              <th colspan="3">Total</th>
              <th>{{ item.balanceAcc | currency }}</th>
              <!-- <th>teste</th> -->
            </tr>
          </template>
        </v-data-table>
      </v-skeleton-loader>
    </v-card-text>
  </div>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
import { mapGetters } from 'vuex'
import { size } from 'lodash'
import { formatDate } from '@/core/service/utils'

export default {
  name: 'UsuarioWebExtratoCredito',
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header table-header-description', text: 'Descrição', value: 'description' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Tipo', value: 'type' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data Compra', value: 'purchase' },
      { align: 'start', class: 'table-header', text: 'Data Ativação', value: 'activate' }
    ],
  }),
  computed: {
    ...mapGetters('usuariosWeb', {listExtratoCredito: 'listExtratoCredito', item: 'item', loadingTable: 'loadingTable'}),
    variables: () => variables,
  },

  methods: {
    getColor (type) {
      switch (type) {
        case 'D':
          return this.variables.colorError
        case 'C':
          return this.variables.colorSecondary
      }
    },
    totalValue () {
      let self = this
      let total = 0
      self.listExtratoCredito.forEach(element => {
        if (element.entryType.type === 'C') {
          total += parseFloat(element.value)
        } else {
          total -= parseFloat(element.value)
        }
      });
      return total
    },

    exibirCod (entries) {
      if (size(entries) && size(entries.credits)) return entries.credits[0].cod
      if (size(entries) && size(entries.cupons)) return entries.cupons

      return '-'
    },

    isCreditsOrCupons (entries) {
      if (size(entries) && size(entries.credits)) return 'CREDITO'
      if (size(entries) && size(entries.cupons)) return 'CUPOM'

      return ''
    },

    exibirDateCompra (entries) {
      if (size(entries) && size(entries.credits))  return formatDate(entries.credits[0].created)
      if (size(entries) && size(entries.cupons)) return formatDate(entries.cupons[0].created)
      if (size(entries) && size(entries.expiredCredits)) return formatDate(entries.expiredCredits[0].created)

      return formatDate(entries.created)
    },

    exibirDateAtivacao (entries) {
      if (size(entries) && size(entries.credits)) return formatDate(entries.credits[0].dtActivated)
      return '-'
    },

    getExtraInfo (credits) {
      if (credits && credits.length > 0 && credits[0].extraInfo && credits[0].extraInfo.type) return credits[0].extraInfo.type
      return '-'
    }
  }
}
</script>

<style lang="scss">
  .table-extrato-creditos {
    .table-header-description {
      width: 300px !important;
    }
  }
</style>