var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-text',{staticClass:"modal-cadastro-form"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"350px","type":"table"}},[_c('v-data-table',{staticClass:"table-extrato-creditos",attrs:{"headers":_vm.headers,"items":_vm.listExtratoCredito,"flat":"","dense":""},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(("color: " + (_vm.getColor(item.entryType ? item.entryType.type : null)) + ";"))},[_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getExtraInfo(item.credits))+" ")]}},{key:"item.cod",fn:function(ref){
var item = ref.item;
return [(_vm.isCreditsOrCupons(item) === 'CUPOM')?_c('v-menu',{attrs:{"bottom":"","offset-y":"","max-height":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","tile":"","color":_vm.variables.colorPrimary,"dark":""}},'v-btn',attrs,false),on),[_vm._v(" Ver cupons "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-arrow-down-drop-circle-outline ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.exibirCod(item)),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.cod))])],1)}),1)],1):_c('span',[_vm._v(_vm._s(_vm.exibirCod(item)))])]}},{key:"item.purchase",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.exibirDateCompra(item))+" ")]}},{key:"item.activate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.exibirDateAtivacao(item))+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm._f("currency")(_vm.item.balanceAcc)))])])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }